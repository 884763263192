export const TestIDs = {
  layouts: {
    dashboardLayout: {
      topBar: {
        userDropDown: 'layouts-dasboardLayout-topBar-userDropDown',
        userDropDownMenu: 'layouts-dasboardLayout-topBar-userDropDownMenu',
        userDropDownLogout: 'layouts-dasboardLayout-topBar-userDropDownLogout',
        userDropDownLinkLegalEntity: 'layouts-dasboardLayout-topBar-userDropDownLinkLegalEntity',
        userDropDownLinkProfile: 'layouts-dasboardLayout-topBar-userDropDownLinkProfile',
      },
      navBar: {
        wrapper: 'layouts-dashboardLayout-navBar-wrapper',
        items: {
          constructionProjects: 'layouts-dashboardLayout-navBar-items-constructionProjects',
          constructionProjectsList: 'layouts-dashboardLayout-navBar-items-constructionProjectsList',
          dashboard: 'layouts-dashboardLayout-navBar-items-dashboard',
          employees: 'layouts-dashboardLayout-navBar-items-employees',
          employeesList: 'layouts-dashboardLayout-navBar-items-employeesList',
          customers: 'layouts-dashboardLayout-navBar-items-customers',
          customerList: 'layouts-dashboardLayout-navBar-items-customerList',
          customerCreate: 'layouts-dashboardLayout-navBar-items-customerCreate',
          createEmployee: 'layouts-dashboardLayout-navBar-items-createEmployee',
          projectNotes: 'layouts-dashboardLayout-navBar-items-projectNotes',
          projectNotesList: 'layouts-dashboardLayout-navBar-items-projectNotesList',
          createProjectNote: 'layouts-dashboardLayout-navBar-items-createProjectNote',
          documents: 'layouts-dashboardLayout-navBar-items-documents',
          documentsList: 'layouts-dashboardLayout-navBar-items-documentsList',
          documentsCreate: 'layouts-dashboardLayout-navBar-items-documentsCreate',
          legalEntityName: 'layouts-dashboardLayout-navBar-items-legalEntityName',
          legalEntityLogo: 'layouts-dashboardLayout-navBar-items-legalEntityLogo',
          exports: 'layouts-dashboardLayout-navBar-items-exports',
          exportTimeBookings: 'layouts-dashboardLayout-navBar-items-exportTimeBookings',
          reports: 'layouts-dashboardLayout-navBar-items-reports',
          reportsList: 'layouts-dashboardLayout-navBar-items-reportsList',
          reportsCreate: 'layouts-dashboardLayout-navBar-items-reportsCreate',
        },
      },
    },
  },
  views: {
    common: {
      auth: {
        loginView: {
          loginForm: {
            emailField: 'views-common-auth-loginView-loginForm-emailField',
            passwordField: 'views-common-auth-loginView-loginForm-passwordField',
            submitError: 'views-common-auth-loginView-loginForm-submitError',
            loginButton: 'views-common-auth-loginView-loginForm-loginButton',
            demoLoginButton: 'views-common-auth-loginView-loginForm-demoLoginButton',
          },
        },
      },
    },
    contractor: {
      contractorProject: {
        dayLaborView: {
          header: {
            exportButton: 'views-contractor-contractorProject-dayLaborView-header-exportButton',
          },
          dayLaborTable: {
            container: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-container',
            date: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-date',
            description: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-description',
            materialsAndTools: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-materialsAndTools',
            recordedAt: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-recordedAt',
            tableRow: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-tableRow',
            user: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-user',
            workMinutes: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-workMinutes',
            editButton: 'views-contractor-contractorProject-dayLaborView-dayLaborTable-editButton',
          },
        },
        editDayLabor: {
          date: 'views-contractor-contractorProject-editDayLabor-date',
          employees: 'views-contractor-contractorProject-editDayLabor-employees',
          description: 'views-contractor-contractorProject-editDayLabor-description',
          materialsAndTools: 'views-contractor-contractorProject-editDayLabor-materialsAndTools',
          cancelButton: 'views-contractor-contractorProject-editDayLabor-cancelButton',
          submitButton: 'views-contractor-contractorProject-editDayLabor-submitButton',
        },
        contractorProjectsView: {
          header: {
            createProjectButton: 'views-contractor-contractorProject-contractorProjectsView-header-createProjectButton',
          },
          projectsTable: {
            projectsTableContainer:
              'views-contractor-contractorProject-contractorProjectsView-projectsTable-projectsTableContainer',
            categorySelect: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-categorySelect',
            categoryOption: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-categoryOption',
            customerSelect: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-customerSelect',
            customerOption: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-customerOption',
            statusSelect: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-statusSelect',
            statusOption: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-statusOption',
            checkInStatusSelect:
              'views-contractor-contractorProject-contractorProjectsView-projectsTable-checkInStatusSelect',
            checkInStatusOption:
              'views-contractor-contractorProject-contractorProjectsView-projectsTable-checkInStatusOption',
            tableRow: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableRow',
            tableCells: {
              projectName:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-projectName',
              projectNumber:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-projectNumber',
              category: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-category',
              customer: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-customer',
              totalProgress:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-totalProgress',
              priceProgress:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-priceProgress',
              hoursWorked:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-hoursWorked',
              checkInStatus:
                'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-checkInStatus',
              status: 'views-contractor-contractorProject-contractorProjectsView-projectsTable-tableCells-status',
            },
          },
        },
        contractorProjectCreateView: {
          projectCreateForm: {
            category: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-category',
            customer: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-customer',
            customerSelectField:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-customerSelectField',
            customerOption: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-customerOption',
            addCustomerButton:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-addCustomerButton',
            costUnit: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-costUnit',
            city: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-city',
            projectCoordinates:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-projectCoordinates',
            costCenter: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-costCenter',
            generalProjectMinutes:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-generalProjectMinutes',
            country: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-country',
            copyCustomerAddressButton:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-copyCustomerAddressButton',
            customerLoadError:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-customerLoadError',
            endDate: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-endDate',
            name: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-name',
            offerNumber: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-offerNumber',
            postalCode: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-postalCode',
            projectNumber: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-projectNumber',
            startDate: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-startDate',
            status: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-status',
            address: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-address',
            submit: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-submit',
            map: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-map',
            mapError: 'views-contractor-contractorProject-projectCreateView-projectCreateForm-mapError',
            checkboxSyncAddress:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-checkboxSyncAddress',
            checkboxSyncMarker:
              'views-contractor-contractorProject-projectCreateView-projectCreateForm-checkboxSyncMarker',
          },
          customerCreateModal: {
            container: 'views-contractor-contractorProject-projectCreateView-customerCreateModal-container',
            cardHeader: 'views-contractor-contractorProject-projectCreateView-customerCreateModal-cardHeader',
            closeButton: 'views-contractor-contractorProject-projectCreateView-customerCreateModal-closeButton',
            customerForm: 'views-contractor-contractorProject-projectCreateView-customerCreateModal-customerForm',
          },
        },
      },
      dashboard: {
        dashboardView: {
          infoTiles: {
            projectProgress: 'views-contractor-dashboard-dashboardView-infoTiles-projectProgress',
            activeProjects: 'views-contractor-dashboard-dashboardView-infoTiles-activeProjects',
            preparationProjects: 'views-contractor-dashboard-dashboardView-infoTiles-preparationProjects',
            finishedProjects: 'views-contractor-dashboard-dashboardView-infoTiles-finishedProjects',
          },
          timeBookingsTurnoverChart: {
            title: 'views-contractor-dashboard-dashboardView-timeBookingsTurnoverChart-title',
            dateButton: 'views-contractor-dashboard-dashboardView-timeBookingsTurnoverChart-dateButton',
            chart: 'views-contractor-dashboard-dashboardView-timeBookingsTurnoverChart-chart',
          },
          photosTile: {
            title: 'views-contractor-dashboard-dashboardView-photosTile-title',
            viewPhotos: 'views-contractor-dashboard-dashboardView-photosTile-viewPhotos',
            photosList: 'views-contractor-dashboard-dashboardView-photosTile-photosList',
          },
          employeesTile: {
            title: 'views-contractor-dashboard-dashboardView-map-title',
            statusSelect: 'views-contractor-dashboard-dashboardView-employeesTile-statusSelect',
            statusOption: 'views-contractor-dashboard-dashboardView-employeesTile-statusOption',
            projectSelect: 'views-contractor-dashboard-dashboardView-employeesTile-projectSelect',
            projectOption: 'views-contractor-dashboard-dashboardView-employeesTile-projectOption',
            tableContainer: 'views-contractor-dashboard-dashboardsView-employeesTile-tableContainer',
            tableCells: {
              employee: 'views-contractor-dashboard-dashboardView-employeesTile-tableCells-employee',
              projectAddress: 'views-contractor-dashboard-dashboardView-employeesTile-tableCells-projectAddress',
              status: 'views-contractor-dashboard-dashboardView-employeesTile-tableCells-status',
              modifiedAt: 'views-contractor-dashboard-dashboardView-employeesTile-tableCells-modifiedAt',
            },
            employeeRow: 'views-contractor-dashboard-dashboardView-employeesTile-employeeRow',
          },
          map: {
            title: 'views-contractor-dashboard-dashboardView-map-title',
            statusSelect: 'views-contractor-dashboard-dashboardView-map-statusSelect',
            checkInStatusSelect: 'views-contractor-dashboard-dashboardView-map-checkInStatusSelect',
            statusOption: 'views-contractor-dashboard-dashboardView-map-statusOption',
            checkInStatusOption: 'views-contractor-dashboard-dashboardView-map-checkInStatusOption',
            toProjectButton: 'views-contractor-dashboard-dashboardView-map-toProjectButton',
          },
        },
      },
      reports: {
        ReportsView: {
          table: {
            createButton: 'views-contractor-reports-ReportsView-table-createButton',
            container: 'views-contractor-reports-ReportsView-table-container',
            row: 'views-contractor-reports-ReportsView-table-row',
            cell: {
              startDate: 'views-contractor-reports-ReportsView-table-cell-startDate',
              endDate: 'views-contractor-reports-ReportsView-table-cell-endDate',
              kind: 'views-contractor-reports-ReportsView-table-cell-kind',
              contractorProject: 'views-contractor-reports-ReportsView-table-cell-contractorProject',
              status: 'views-contractor-reports-ReportsView-table-cell-status',
              size: 'views-contractor-reports-ReportsView-table-cell-size',
              createdAt: 'views-contractor-reports-ReportsView-table-cell-createdAt',
              deleteButton: 'views-contractor-reports-ReportsView-table-cell-deleteButton',
              downloadButton: 'views-contractor-reports-ReportsView-table-cell-downloadButton',
            },
            filters: {
              contractorProjects: {
                textField: 'views-contractor-reports-ReportsView-table-filters-contractorProjects-textField',
                option: 'views-contractor-reports-ReportsView-table-filters-contractorProjects-option',
              },
              status: {
                select: 'views-contractor-reports-ReportsView-table-filters-status-select',
                option: 'views-contractor-reports-ReportsView-table-filters-status-option',
              },
              creator: {
                textField: 'views-contractor-reports-ReportsView-table-filters-creator-textField',
                option: 'views-contractor-reports-ReportsView-table-filters-creator-option',
              },
            },
            bulkOperations: {
              deleteButton: 'views-contractor-reports-ReportsView-table-bulkOperations-deleteButton',
            },
          },
          createForm: {
            cancelButton: 'views-contractor-reports-ReportsView-createForm-cancelButton',
            endDate: 'views-contractor-reports-ReportsView-createForm-endDate',
            project: {
              select: 'views-contractor-reports-ReportsView-createForm-project-select',
              textField: 'views-contractor-reports-ReportsView-createForm-project-textField',
              option: 'views-contractor-reports-ReportsView-createForm-project-option',
              error: 'views-contractor-reports-ReportsView-createForm-project-error',
            },
            startDate: 'views-contractor-reports-ReportsView-createForm-startDate',
            submitButton: 'views-contractor-reports-ReportsView-createForm-submitButton',
            resetButton: 'views-contractor-reports-ReportsView-createForm-resetButton',
          },
        },
      },
      exports: {
        TimeBookingsView: {
          form: {
            startDate: 'views-contractor-exports-TimeBookingsView-form-startDate',
            endDate: 'views-contractor-exports-TimeBookingsView-form-endDate',
            user: {
              select: 'views-contractor-exports-TimeBookingsView-form-user-select',
              textField: 'views-contractor-exports-TimeBookingsView-form-user-textField',
              option: 'views-contractor-exports-TimeBookingsView-form-user-option',
            },
            project: {
              select: 'views-contractor-exports-TimeBookingsView-form-project-select',
              textField: 'views-contractor-exports-TimeBookingsView-form-project-textField',
              option: 'views-contractor-exports-TimeBookingsView-form-project-option',
            },
          },
        },
      },
      projectNotes: {
        projectNotesList: {
          header: {
            createNoteButton: 'views-contractor-projectNotes-projectNotesList-header-createNoteButton',
          },
          projectNotesTable: {
            projectNotesTableContainer:
              'views-contractor-projectNotes-projectNotesList-projectNotesTable-projectNotesTableContainer',
            tableRow: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableRow',
            filterTextField: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-filterTextField',
            selectOption: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-selectOption',
            createdBySelectField:
              'views-contractor-projectNotes-projectNotesList-projectNotesTable-createdBySelectField',
            createdBySelectOption:
              'views-contractor-projectNotes-projectNotesList-projectNotesTable-createdBySelectOption',
            tableCells: {
              date: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableCells-date',
              projectName: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableCells-projectName',
              noteText: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableCells-noteText',
              editButton: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableCells-editButton',
              deleteButton: 'views-contractor-projectNotes-projectNotesList-projectNotesTable-tableCells-deleteButton',
            },
          },
        },
        projectNoteCreateView: {
          projectNoteCreateForm: {
            projectNameSelect:
              'views-contractor-projectNotes-projectNoteCreateView-projectNoteCreateForm-projectNameSelect',
            projectNameOption:
              'views-contractor-projectNotes-projectNoteCreateView-projectNoteCreateForm-projectNameOption',
            noteText: 'views-contractor-projectNotes-projectNoteCreateView-projectNoteCreateForm-noteText',
            submitButton: 'views-contractor-projectNotes-projectNoteCreateView-projectNoteCreateForm-submitButton',
          },
        },
      },
      specification: {
        specificationsView: {
          header: {
            createSpecificationButton:
              'views-contractor-specification-specificationsView-header-createSpecificationButton',
          },
          specificationsTable: {
            container: 'views-contractor-specification-specificationsView-specificationsTable-container',
            contractorProjectName:
              'views-contractor-specification-specificationsView-specificationsTable-contractorProjectName',
            contractorProjectsFilterOption:
              'views-contractor-specification-specificationsView-specificationsTable-contractorProjectsFilterOption',
            contractorProjectsFilter:
              'views-contractor-specification-specificationsView-specificationsTable-contractorProjectsFilter',
            createdAt: 'views-contractor-specification-specificationsView-specificationsTable-createdAt',
            priceProgress: 'views-contractor-specification-specificationsView-specificationsTable-priceProgress',
            hoursWorked: 'views-contractor-specification-specificationsView-specificationsTable-hoursWorked',
            tableRow: 'views-contractor-specification-specificationsView-specificationsTable-tableRow',
            title: 'views-contractor-specification-specificationsView-specificationsTable-title',
            uploadX84: 'views-contractor-specification-specificationsView-specificationsTable-uploadX84',
            detailButton: 'views-contractor-specification-specificationsView-specificationsTable-detailButton',
            import: 'views-contractor-specification-specificationsView-specificationsTable-import',
          },
        },
        specificationCreateView: {
          specificationCreateForm: {
            cancel: 'views-contractor-specification-specificationCreateView-specificationCreateForm-cancel',
            fileCard: 'views-contractor-specification-specificationCreateView-specificationCreateForm-fileCard',
            gaebFileSelect:
              'views-contractor-specification-specificationCreateView-specificationCreateForm-gaebFileSelect',
            projectSelect:
              'views-contractor-specification-specificationCreateView-specificationCreateForm-projectSelect',
            projectError: 'views-contractor-specification-specificationCreateView-specificationCreateForm-projectError',
            submit: 'views-contractor-specification-specificationCreateView-specificationCreateForm-submit',
          },
        },
      },
      timeBooking: {
        timeBookingsView: {
          header: {
            createButton: 'views-contractor-timeBookings-timeBookingsView-header-createButton',
            exportButton: 'views-contractor-timeBookings-timeBookingsView-header-exportButton',
          },
          timeBookingsTable: {
            container: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-container',
            contractorProjectNameFilterTextField:
              'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-contractorProjectNameFilterTextField',
            createdByFilterTextField:
              'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-createdByFilterTextField',
            usersFilterTextField:
              'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-usersFilterTextField',
            date: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-date',
            endTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-endTime',
            startTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-startTime',
            tableRow: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-tableRow',
            workMinutes: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-workMinutes',
            editButton: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-editButton',
            deleteButton: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-deleteButton',
            bulkDeleteButton: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-bulkDeleteButton',
            expandableRow: {
              personnelNumber:
                'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-personnelNumber',
              creator: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-creator',
              modifiedBy: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-modifiedBy',
              workTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-workTime',
              totalTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-totalTime',
              journeyTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-journeyTime',
              pauseTime: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-pauseTime',
              checkIn: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-checkIn',
              checkOut: 'views-contractor-timeBookings-timeBookingsView-timeBookingsTable-expandableRow-checkOut',
            },
          },
        },
        timeBookingsEditView: {
          errorAlert: 'views-contractor-timeBookings-timeBookingsEditView-errorAlert',
        },
        timeBookingsForm: {
          title: 'views-contractor-timeBookings-timeBookingsForm-title',
          user: 'views-contractor-timeBookings-timeBookingsForm-user',
          userTextInput: 'views-contractor-timeBookings-timeBookingsForm-userTextInput',
          userOption: 'views-contractor-timeBookings-timeBookingsForm-userOption',
          contractorProjectName: 'views-contractor-timeBookings-timeBookingsForm-contractorProjectName',
          startedAt: 'views-contractor-timeBookings-timeBookingsForm-startedAt',
          finishedAt: 'views-contractor-timeBookings-timeBookingsForm-finishedAt',
          journeyTime: 'views-contractor-timeBookings-timeBookingsForm-journeyTime',
          pauseTime: 'views-contractor-timeBookings-timeBookingsForm-pauseTime',
          workTime: 'views-contractor-timeBookings-timeBookingsForm-workTime',
          totalTime: 'views-contractor-timeBookings-timeBookingsForm-totalTime',
          resetButton: 'views-contractor-timeBookings-timeBookingsForm-resetButton',
          submitButton: 'views-contractor-timeBookings-timeBookingsForm-submitButton',
          projectNameTextField: 'views-contractor-timeBookings-timeBookingsForm-projectNameTextField',
          projectNameOption: 'views-contractor-timeBookings-timeBookingsForm-projectNameOption',
        },
      },
    },
    generalContractor: {
      constructionProjectsView: {
        projectsTable: {
          projectsTableContainer:
            'views-generalContractor-constructionProjectsView-projectsTable-projectsTableContainer',
          statusSelect: 'views-generalContractor-constructionProjectsView-projectsTable-statusSelect',
          statusOption: 'views-generalContractor-constructionProjectsView-projectsTable-statusOption',
          customerSelect: 'views-generalContractor-constructionProjectsView-projectsTable-customerSelect',
          customerOption: 'views-generalContractor-constructionProjectsView-projectsTable-customerOption',
          checkInStatusSelect: 'views-generalContractor-constructionProjectsView-projectsTable-checkInStatusSelect',
          checkInStatusOption: 'views-generalContractor-constructionProjectsView-projectsTable-checkInStatusOption',
          tableCells: {
            projectName: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-projectName',
            projectNumber: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-projectNumber',
            customer: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-customer',
            totalProgress: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-totalProgress',
            checkInStatus: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-checkInStatus',
            status: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-status',
            detailButton: 'views-generalContractor-constructionProjectsView-projectsTable-tableCells-detailButton',
          },
          tableRow: 'views-generalContractor-constructionProjectsView-projectsTable-tableRow',
        },
      },
      constructionProjectOverviewView: {
        header: {
          editProject: 'views-generalContractor-constructionProjectOverviewView-header-editProject',
        },
        priceProgressInfoBox: {
          detail: 'views-generalContractor-constructionProjectOverviewView-priceProgressInfoBox-detail',
          percentageOnly: 'views-generalContractor-constructionProjectOverviewView-priceProgressInfoBox-percentageOnly',
        },
        timeEstimateInfoBox: {
          detail: 'views-generalContractor-constructionProjectOverviewView-timeEstimateInfoBox-detail',
          percentageOnly: 'views-generalContractor-constructionProjectOverviewView-timeEstimateInfoBox-percentageOnly',
        },
        photosCard: {
          photosCardContainer: 'views-generalContractor-constructionProjectOverviewView-photosCard-photosCardContainer',
          showAllButton: 'views-generalContractor-constructionProjectOverviewView-photosCard-showAllButton',
        },
        detailCard: {
          detailCardContainer: 'views-generalContractor-constructionProjectOverviewView-detailCard-detailCardContainer',
          projectAddress: 'views-generalContractor-constructionProjectOverviewView-detailCard-projectAddress',
          customer: 'views-generalContractor-constructionProjectOverviewView-detailCard-customer',
          customerAddress: 'views-generalContractor-constructionProjectOverviewView-detailCard-customerAddress',
          projectNumber: 'views-generalContractor-constructionProjectOverviewView-detailCard-projectNumber',
          projectBegin: 'views-generalContractor-constructionProjectOverviewView-detailCard-projectBegin',
          projectEnd: 'views-generalContractor-constructionProjectOverviewView-detailCard-projectEnd',
          projectStatus: 'views-generalContractor-constructionProjectOverviewView-detailCard-projectStatus',
          contractorCount: 'views-generalContractor-constructionProjectOverviewView-detailCard-contractorCount',
          photos: 'views-generalContractor-constructionProjectOverviewView-detailCard-photos',
        },
        documentsCard: {
          container: 'views-shared-generalContractor-constructionProjectOverviewView-documentsCard-container',
          headline: 'views-shared-generalContractor-constructionProjectOverviewView-documentsCard-headline',
          createButton: 'views-shared-generalContractor-constructionProjectOverviewView-documentsCard-createButton',
          showAllButton: 'views-shared-generalContractor-constructionProjectOverviewView-documentsCard-showAllButton',
        },
        timeBookingsTurnoverChart: {
          title: 'views-generalContractor-constructionProjectOverviewView-timeBookingsTurnoverChart-title',
          dateButton: 'views-generalContractor-constructionProjectOverviewView-timeBookingsTurnoverChart-dateButton',
        },
        contractorProjects: {
          contractorProjectsTable:
            'views-generalContractor-constructionProjectOverviewView-contractorProjects-contractorProjectsTable',
          statusSelect: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-statusSelect',
          statusOption: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-statusOption',
          categorySelect: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-categorySelect',
          categoryOption: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-categoryOption',
          tradeOption: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-tradeOption',
          tradeSelect: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-tradeSelect',
          checkInStatusOption:
            'views-generalContractor-constructionProjectOverviewView-contractorProjects-checkInStatusOption',
          checkInStatusSelect:
            'views-generalContractor-constructionProjectOverviewView-contractorProjects-checkInStatusSelect',
          tradeSelectSearchField:
            'views-generalContractor-constructionProjectOverviewView-contractorProjects-tradeSelectSearchField',
          tableRow: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableRow',
          tableCells: {
            projectConnectionName:
              'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-projectConnectionName',
            contractorName:
              'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-contractorName',
            category: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-category',
            totalProgress:
              'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-totalProgress',
            checkInStatus:
              'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-checkInStatus',
            status: 'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-status',
            detailButton:
              'views-generalContractor-constructionProjectOverviewView-contractorProjects-tableCells-detailButton',
          },
        },
        lastActivityTile: {
          container: 'views-generalContractor-constructionProjectOverviewView-lastActivityTile-container',
        },
      },
    },
    shared: {
      customers: {
        table: {
          container: 'views-shared-customers-table-container',
          row: 'views-shared-customers-table-row',
          tableBulkDeleteButton: 'views-shared-customers-table-tableBulkDeleteButton',
          cells: {
            kind: 'views-shared-customers-table-cells-kind',
            companyName: 'views-shared-customers-table-cells-companyName',
            recipient: 'views-shared-customers-table-cells-recipient',
            contactPerson: 'views-shared-customers-table-cells-contactPerson',
            address: 'views-shared-customers-table-cells-address',
            customerNumber: 'views-shared-customers-table-cells-customerNumber',
            email: 'views-shared-customers-table-cells-email',
            phoneNumber: 'views-shared-customers-table-cells-phoneNumber',
            projectCount: 'views-shared-customers-table-cells-projectCount',
            status: 'views-shared-customers-table-cells-status',
            editButton: 'views-shared-customers-table-cells-editButton',
            deleteButton: 'views-shared-customers-table-cells-deleteButton',
          },
          filters: {
            kind: {
              textField: 'views-shared-customers-table-filters-kind-textField',
              option: 'views-shared-customers-table-filters-kind-option',
            },
            status: {
              textField: 'views-shared-customers-table-filters-status-textField',
              option: 'views-shared-customers-table-filters-status-option',
            },
          },
        },
        edit: {
          errorAlert: 'views-shared-customers-edit-errorAlert',
          deleteButton: 'views-shared-customers-edit-deleteButton',
        },
        form: {
          container: 'views-shared-customers-form-container',
          kind: 'views-shared-customers-form-kind',
          companyName: 'views-shared-customers-form-companyName',
          recipient: 'views-shared-customers-form-recipient',
          firstName: 'views-shared-customers-form-firstName',
          lastName: 'views-shared-customers-form-lastName',
          address: 'views-shared-customers-form-address',
          postalCode: 'views-shared-customers-form-postalCode',
          city: 'views-shared-customers-form-city',
          country: 'views-shared-customers-form-country',
          customerNumber: 'views-shared-customers-form-customerNumber',
          email: 'views-shared-customers-form-email',
          phoneNumber: 'views-shared-customers-form-phoneNumber',
          status: 'views-shared-customers-form-status',
          createdAndModifiedInformation: 'views-shared-customers-form-createdAndModifiedInformation',
          createdAndModifiedInfoHeader: 'views-shared-customers-form-createdAndModifiedInfoHeader',
          createdByContainer: 'views-shared-customers-form-createdByContainer',
          modifiedByContainer: 'views-shared-customers-form-modifiedByContainer',
          resetButton: 'views-shared-customers-form-resetButton',
          submitButton: 'views-shared-customers-form-submitButton',
          errorAlert: 'views-shared-customers-form-errorAlert',
        },
      },
      employee: {
        employeesCreateView: {
          employeesCreateForm: {
            email: 'views-shared-employee-employeesCreateView-employeesCreateForm-email',
            firstName: 'views-shared-employee-employeesCreateView-employeesCreateForm-firstName',
            lastName: 'views-shared-employee-employeesCreateView-employeesCreateForm-lastName',
            phoneMobile: 'views-shared-employee-employeesCreateView-employeesCreateForm-phoneMobile',
            phoneLandline: 'views-shared-employee-employeesCreateView-employeesCreateForm-phoneLandline',
            password: 'views-shared-employee-employeesCreateView-employeesCreateForm-password',
            passwordRepeat: 'views-shared-employee-employeesCreateView-employeesCreateForm-passwordRepeat',
            personnelNumber: 'views-shared-employee-employeesCreateView-employeesCreateForm-personnelNumber',
            roles: 'views-shared-employee-employeesCreateView-employeesCreateForm-roles',
            submit: 'views-shared-employee-employeesCreateView-employeesCreateForm-submit',
            errors: {
              passwordRepeat: 'views-shared-employee-employeesCreateView-employeesCreateForm-errors-passwordRepeat',
              password: 'views-shared-employee-employeesCreateView-employeesCreateForm-errors-password',
            },
          },
        },
        employeesEditView: {
          header: {
            deleteButton: 'views-shared-employee-employeesEditView-header-deleteButton',
          },
        },
        employeesView: {
          header: {
            createEmployee: 'views-shared-employee-employeesView-header-createEmployee',
          },
          employeesTable: {
            employeesTableContainer: 'views-shared-employee-employeesView-employeesTable-employeesTableContainer',
            tableRow: 'views-shared-employee-employeesView-employeesTable-tableRow',
            tableRowDeleteButton: 'views-shared-employee-employeesView-employeesTable-tableRowDeleteButton',
            tableRowDetailButton: 'views-shared-employee-employeesView-employeesTable-tableRowDetailButton',
            bulkDeleteButton: 'views-shared-employee-employeesView-employeesTable-bulkDeleteButton',
            columns: {
              userInfo: 'views-shared-employee-employeesView-employeesTable-columns-userInfo',
              firstName: 'views-shared-employee-employeesView-employeesTable-columns-firstName',
              lastName: 'views-shared-employee-employeesView-employeesTable-columns-lastName',
              email: 'views-shared-employee-employeesView-employeesTable-columns-email',
              personnelNumber: 'views-shared-employee-employeesView-employeesTable-columns-personnelNumber',
              roles: 'views-shared-employee-employeesView-employeesTable-columns-roles',
            },
          },
        },
      },
      documents: {
        documentsView: {
          createDocumentButton: 'views-shared-documents-documentsView-createDocumentButton',
          documentsTable: {
            container: 'views-shared-documents-documentsView-documentsTable-container',
            projectSelect: 'views-shared-documents-documentsView-documentsTable-projectSelect',
            projectSelectSearchField: 'views-shared-documents-documentsView-documentsTable-projectSelectSearchField',
            createdBySelectField: 'views-shared-documents-documentsView-documentsTable-createdBySelectField',
            createdBySelectOption: 'views-shared-documents-documentsView-documentsTable-createdBySelectOption',
            projectOption: 'views-shared-documents-documentsView-documentsTable-projectOption',
            tableRow: 'views-shared-documents-documentsView-documentsTable-tableRow',
            tableRowDeleteButton: 'views-shared-documents-documentsView-documentsTable-tableRowDeleteButton',
            tableRowDownloadButton: 'views-shared-documents-documentsView-documentsTable-tableRowDownloadButton',
            tableRowDetailButton: 'views-shared-documents-documentsView-documentsTable-tableRowDetailButton',
            tableCells: {
              createdAt: 'views-shared-documents-documentsView-documentsTable-tableCells-createdAt',
              document: 'views-shared-documents-documentsView-documentsTable-tableCells-document',
              project: 'views-shared-documents-documentsView-documentsTable-tableCells-project',
              task: 'views-shared-documents-documentsView-documentsTable-tableCells-task',
              createdBy: 'views-shared-documents-documentsView-documentsTable-tableCells-createdBy',
            },
          },
        },
        documentsCreate: {
          fileSelect: 'views-shared-documents-documentsCreate-fileSelect',
          projectSelect: 'views-shared-documents-documentsCreate-projectSelect',
          projectOption: 'views-shared-documents-documentsCreate-projectOption',
          projectError: 'views-shared-documents-documentsCreate-projectError',
          cancel: 'views-shared-documents-documentsCreate-cancel',
          submit: 'views-shared-documents-documentsCreate-submit',
          fileInProgress: {
            container: 'views-shared-documents-documentsCreate-fileInProgress-container',
          },
        },
      },
      photo: {
        photosView: {
          tableViewButton: 'views-shared-photosView-tableViewButton',
          gridViewButton: 'views-shared-photosView-gridViewButton',
          createPhotoButton: 'views-shared-photosView-createPhotoButton',
          photosGrid: {
            missingContent: 'views-shared-photo-photosView-photosGrid-missingContent',
            groupContainerTitle: 'views-shared-photo-photosView-photosGrid-groupContainerTitle',
            groupContainer: 'views-shared-photo-photosView-photosGrid-groupContainer',
            selectButton: 'views-shared-photo-photosView-photosGrid-selectButton',
            selectAllMonthly: 'views-shared-photo-photosView-photosGrid-selectAllMonthly',
            downloadButton: 'views-shared-photo-photosView-photosGrid-downloadButton',
            deleteButton: 'views-shared-photo-photosView-photosGrid-deleteButton',
            photoCounter: 'views-shared-photo-photosView-photosGrid-photoCounter',
            cancelButton: 'views-shared-photo-photosView-photosGrid-cancelButton',
          },
          photosCollection: {
            photosCollectionContainer: 'views-shared-photo-photosView-photosCollection-photosCollectionContainer',
            downloadButtonRow: 'views-shared-photo-photosView-photosCollection-downloadButtonRow',
            deleteButtonRow: 'views-shared-photo-photosView-photosCollection-deleteButtonRow',
            editButtonRow: 'views-shared-photo-photosView-photosCollection-editButtonRow',
            bulkOperationsDownloadButton: 'views-shared-photo-photosView-photosCollection-bulkOperationsDownloadButton',
            bulkOperationsDeleteButton: 'views-shared-photo-photosView-photosCollection-bulkOperationsDeleteButton',
            projectOption: 'views-shared-photo-photosView-photosCollection-projectOption',
            projectSelect: 'views-shared-photo-photosView-photosCollection-projectSelect',
            taskFilterChip: 'views-shared-photo-photosView-photosCollection-taskFilterChip',
            projectSelectSearchField: 'views-shared-photo-photosView-photosCollection-projectSelectSearchField',
            createdBySelectField: 'views-shared-photo-photosView-photosCollection-createdBySelectField',
            createdBySelectOption: 'views-shared-photo-photosView-photosCollection-createdBySelectOption',
            recordedAtStartDate: 'views-shared-photo-photosView-photosCollection-recordedAtStartDate',
            recordedAtEndDate: 'views-shared-photo-photosView-photosCollection-recordedAtEndDate',
            tableRow: 'views-shared-photo-photosView-photosCollection-tableRow',
            tableRowImage: 'views-shared-photo-photosView-photosCollection-tableRowImage',
            tableCells: {
              photo: 'views-shared-photo-photosView-photosCollection-tableCells-photo',
              recordedAt: 'views-shared-photo-photosView-photosCollection-tableCells-recordedAt',
              category: 'views-shared-photo-photosView-photosCollection-tableCells-category',
              projectName: 'views-shared-photo-photosView-photosCollection-tableCells-projectName',
              trades: 'views-shared-photo-photosView-photosCollection-tableCells-trades',
              creator: 'views-shared-photo-photosView-photosCollection-tableCells-creator',
              task: 'views-shared-photo-photosView-photosCollection-tableCells-task',
              comment: 'views-shared-photo-photosView-photosCollection-tableCells-comment',
            },
            tradeOption: 'views-shared-photo-photosView-photosCollection-tradeOption',
            tradeSelect: 'views-shared-photo-photosView-photosCollection-tradeSelect',
            tradeSelectSearchField: 'views-shared-photo-photosView-photosCollection-tradeSelectSearchField',
            categoryOption: 'views-shared-photo-photosView-photosCollection-categoryOption',
            categorySelect: 'views-shared-photo-photosView-photosCollection-categorySelect',
            categorySelectSearchField: 'views-shared-photo-photosView-photosCollection-categorySelectSearchField',
            subContractorOption: 'views-shared-photo-photosView-photosCollection-subContractorOption',
            subContractorSelect: 'views-shared-photo-photosView-photosCollection-subContractorSelect',
            subContractorSelectSearchField:
              'views-shared-photo-photosView-photosCollection-subContractorSelectSearchField',
          },
        },
        photosCreate: {
          fileSelect: 'views-shared-photo-photosCreate-fileSelect',
          fileInProgress: {
            cancelButton: 'views-shared-photo-photosCreate-fileInProgress-cancelButton',
            container: 'views-shared-photo-photosCreate-fileInProgress-container',
          },
          fileCard: 'views-shared-photo-photosCreate-fileCard',
          projectSelect: 'views-shared-photo-photosCreate-projectSelect',
          projectOption: 'views-shared-photo-photosCreate-projectOption',
          categorySelect: 'views-shared-photo-photosCreate-categorySelect',
          taskChip: 'views-shared-photo-photosCreate-taskChip',
          comment: 'views-shared-photo-photosCreate-comment',
          cancel: 'views-shared-photo-photosCreate-cancel',
          submit: 'views-shared-photo-photosCreate-submit',
          successModal: 'views-shared-photo-photosCreate-successModal',
          successModalCloseButton: 'views-shared-photo-photosCreate-successModalButton',
        },
        photosEditView: {
          error: 'views-shared-photo-photosEditView-error',
          teamDayLaborAssignedError: 'views-shared-photo-photosEditView-teamDayLaborAssignedError',
          photosEditForm: {
            image: 'views-shared-photo-photosEditView-photosEditForm-image',
            submitError: 'views-shared-photo-photosEditView-photosEditForm-submitError',
            additionalInformation: 'views-shared-photo-photosEditView-photosEditForm-additionalInformation',
            contractorProjects: 'views-shared-photo-photosEditView-photosEditForm-contractorProjects',
            contractorProjectsOption: 'views-shared-photo-photosEditView-photosEditForm-contractorProjectsOption',
            contractorProjectsError: 'views-shared-photo-photosEditView-photosEditForm-contractorProjectsError',
            tasks: 'views-shared-photo-photosEditView-photosEditForm-tasks',
            tasksError: 'views-shared-photo-photosEditView-photosEditForm-tasksError',
            category: 'views-shared-photo-photosEditView-photosEditForm-category',
            categoryOption: 'views-shared-photo-photosEditView-photosEditForm-categoryOption',
            categoryError: 'views-shared-photo-photosEditView-photosEditForm-categoryError',
            comments: 'views-shared-photo-photosEditView-photosEditForm-comments',
            submitButton: 'views-shared-photo-photosEditView-photosEditForm-submitButton',
            resetButton: 'views-shared-photo-photosEditView-photosEditForm-resetButton',
          },
        },
      },
      contractorProject: {
        contractorProjectOverviewView: {
          header: {
            editProject: 'views-shared-contractorProject-contractorProjectOverviewView-header-editProject',
          },
          priceProgressInfoBox: {
            detail: 'views-shared-contractorProject-contractorProjectOverviewView-priceProgressInfoBox-detail',
            percentageOnly:
              'views-shared-contractorProject-contractorProjectOverviewView-priceProgressInfoBox-percentageOnly',
          },
          timeEstimateInfoBox: {
            detail: 'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateInfoBox-detail',
            percentageOnly:
              'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateInfoBox-percentageOnly',
          },
          photosCard: {
            photosCardContainer:
              'views-shared-contractorProject-contractorProjectOverviewView-photosCard-photosCardContainer',
            showAllButton: 'views-shared-contractorProject-contractorProjectOverviewView-photosCard-showAllButton',
          },
          dayLaborCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-dayLaborCard-container',
            headline: 'views-shared-contractorProject-contractorProjectOverviewView-dayLaborCard-headline',
            showAllButton: 'views-shared-contractorProject-contractorProjectOverviewView-dayLaborCard-showAllButton',
          },
          documentsCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-documentsCard-container',
            headline: 'views-shared-contractorProject-contractorProjectOverviewView-documentsCard-headline',
            createButton: 'views-shared-contractorProject-contractorProjectOverviewView-documentsCard-createButton',
            showAllButton: 'views-shared-contractorProject-contractorProjectOverviewView-documentsCard-showAllButton',
          },
          detailCard: {
            detailCardContainer:
              'views-shared-contractorProject-contractorProjectOverviewView-detailCard-detailCardContainer',
            subContractorTitle:
              'views-shared-contractorProject-contractorProjectOverviewView-detailCard-subContractorTitle',
            contractorName: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-contractorName',
            contractorAddress:
              'views-shared-contractorProject-contractorProjectOverviewView-detailCard-contractorAddress',
            trades: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-trades',
            projectAddress: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectAddress',
            customer: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-customer',
            customerAddress: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-customerAddress',
            projectNumber: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectNumber',
            projectStatus: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectStatus',
            projectCategory: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectCategory',
            photos: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-photos',
            documents: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-documents',
            projectBegin: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectBegin',
            projectEnd: 'views-shared-contractorProject-contractorProjectOverviewView-detailCard-projectEnd',
          },
          projectTimeCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-projectTimeCard-container',
            workTime: 'views-shared-contractorProject-contractorProjectOverviewView-projectTimeCard-workTime',
            dayLaborTime: 'views-shared-contractorProject-contractorProjectOverviewView-projectTimeCard-dayLaborTime',
            totalTime: 'views-shared-contractorProject-contractorProjectOverviewView-projectTimeCard-totalTime',
          },
          timeEstimateCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateCard-container',
            estimatedWorkTime:
              'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateCard-estimatedWorkTime',
            generalProjectHours:
              'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateCard-generalProjectHours',
            totalHours: 'views-shared-contractorProject-contractorProjectOverviewView-timeEstimateCard-totalHours',
          },
          performanceCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-performanceCard-container',
            tabPanel: {
              timeEstimate:
                'views-shared-contractorProject-contractorProjectOverviewView-performanceCard-tabPanel-timeEstimate',
              general: 'views-shared-contractorProject-contractorProjectOverviewView-performanceCard-tabPanel-general',
            },
            tabButton: {
              timeEstimate:
                'views-shared-contractorProject-contractorProjectOverviewView-performanceCard-tabButton-timeEstimate',
              general: 'views-shared-contractorProject-contractorProjectOverviewView-performanceCard-tabButton-general',
            },
          },
          workPerformedChart: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-workPerformedChart-container',
          },
          estimationBalanceChart: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-estimationBalanceChart-container',
          },
          productivityChart: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-productivityChart-container',
          },
          remainingTimeChart: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-remainingTimeChart-container',
          },
          dayLaborChart: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-dayLaborChart-container',
          },
          turnoverPerformanceChart: {
            container:
              'views-shared-contractorProject-contractorProjectOverviewView-turnoverPerformanceChart-container',
          },
          dailyTurnoverChart: {
            dailyTurnoverChartCard:
              'views-shared-contractorProject-contractorProjectOverviewView-dailyTurnoverChart-dailyTurnoverChartCard',
            exportButton:
              'views-shared-contractorProject-contractorProjectOverviewView-dailyTurnoverChart-exportButton',
          },
          taskProgressTable: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-container',
            tableRow: 'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-tableRow',
            tableCells: {
              recordedAt:
                'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-tableCells-recordedAt',
              task: 'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-tableCells-task',
              amount:
                'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-tableCells-amount',
              user: 'views-shared-contractorProject-contractorProjectOverviewView-taskProgressTable-tableCells-user',
            },
          },
          aggregatedTimeBookingsTable: {
            tableRow:
              'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableRow',
            tableCells: {
              date: 'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableCells-date',
              workMinutes:
                'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableCells-workMinutes',
              journeyMinutes:
                'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableCells-journeyMinutes',
              pauseMinutes:
                'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableCells-pauseMinutes',
              userCount:
                'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-tableCells-userCount',
            },
            aggregatedTimeBookingsTableContainer:
              'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-aggregatedTimeBookingsTableContainer',
            showAllButton:
              'views-shared-contractorProject-contractorProjectOverviewView-aggregatedTimeBookingsTable-showAllButton',
          },
          checkInStatusCard: {
            container: 'views-shared-contractorProject-contractorProjectOverviewView-checkInStatusCard-container',
          },
          specificationsTable: {
            specificationsTableContainer:
              'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-specificationsTableContainer',
            tableRow: 'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-tableRow',
            tableCells: {
              title:
                'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-tableCells-title',
              priceProgress:
                'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-tableCells-priceProgress',
              hoursWorked:
                'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-tableCells-hoursWorked',
              createdAt:
                'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-tableCells-createdAt',
            },
            buttons: {
              detail: 'views-shared-contractorProject-contractorProjectOverviewView-specificationsTable-buttons-detail',
            },
          },
          projectOverviewNotesTable: {
            tableContainer:
              'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableContainer',
            tableRow: 'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableRow',
            tableCells: {
              createdAt:
                'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableCells-createdAt',
              createdBy:
                'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableCells-createdBy',
              text: 'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableCells-text',
              editButton:
                'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableCells-editButton',
              deleteButton:
                'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-tableCells-deleteButton',
            },
            createNoteButton:
              'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-createNoteButton',
            viewAllButton:
              'views-shared-contractorProject-contractorProjectOverviewView-projectOverviewNotesTable-viewAllButton',
          },
        },
      },
      settings: {
        productOptimizationHeader: 'views-shared-settings-productOptimizationHeader',
      },
      storage: {
        cardHeader: 'views-shared-storage-cardHeader',
        usedStorage: 'views-shared-storage-usedStorage',
        extraFileQuota: 'views-shared-storage-extraFileQuota',
      },
      specification: {
        taskListsView: {
          priceProgressInfoBox: {
            detail: 'views-shared-specification-taskListsView-priceProgressInfoBox-detail',
            percentageOnly: 'views-shared-specification-taskListsView-priceProgressInfoBox-percentageOnly',
          },
          header: {
            deleteButton: 'views-shared-specification-taskListsView-header-deleteButton',
          },
          timeEstimateInfoBox: {
            detail: 'views-shared-specification-taskListsView-timeEstimateInfoBox-detail',
            percentageOnly: 'views-shared-specification-taskListsView-timeEstimateInfoBox-percentageOnly',
          },
          taskListsTable: {
            tableContainer: 'views-shared-specification-taskListsView-taskListsTable-tableContainer',
            tableRow: 'views-shared-specification-taskListsView-taskListsTable-tableRow',
            tableRowDetailButton: 'views-shared-specification-taskListsView-taskListsTable-tableRowDetailButton',
            viewRemarkTextsButton: 'views-shared-specification-taskListsView-taskListsTable-viewRemarkTextsButton',
            columns: {
              name: 'views-shared-specification-taskListsView-taskListsTable-columns-name',
              priceProgress: 'views-shared-specification-taskListsView-taskListsTable-columns-priceProgress',
              hoursWorked: 'views-shared-specification-taskListsView-taskListsTable-columns-hoursWorked',
            },
          },
        },
        taskOverview: {
          priceProgressCard: 'views-shared-specification-taskOverview-priceProgressCard',
          performedWorkCard: 'views-shared-specification-taskOverview-performedWorkCard',
          minuteValueCard: 'views-shared-specification-taskOverview-minuteValueCard',
          amountCard: 'views-shared-specification-taskOverview-amountCard',
          unitPriceCard: 'views-shared-specification-taskOverview-unitPriceCard',
          contingencyCard: 'views-shared-specification-taskOverview-contingencyCard',
          unboundCard: 'views-shared-specification-taskOverview-unboundCard',
          lumpSumCard: 'views-shared-specification-taskOverview-lumpSumCard',
          shortDescriptionCard: 'views-shared-specification-taskOverview-shortDescriptionCard',
          descriptionCard: 'views-shared-specification-taskOverview-descriptionCard',
          taskProgressTable: {
            container: 'views-shared-specification-taskOverview-taskProgressTable-container',
            tableRow: 'views-shared-specification-taskOverview-taskProgressTable-tableRow',
            tableCells: {
              recordedAt: 'views-shared-specification-taskOverview-taskProgressTable-tableCells-recordedAt',
              amount: 'views-shared-specification-taskOverview-taskProgressTable-tableCells-amount',
              unit: 'views-shared-specification-taskOverview-taskProgressTable-tableCells-unit',
              comment: 'views-shared-specification-taskOverview-taskProgressTable-tableCells-comment',
              user: 'views-shared-specification-taskOverview-taskProgressTable-tableCells-user',
            },
          },
          remarkTextsTile: {
            container: 'views-shared-specification-taskOverview-remarkTextsTile-container',
            viewAllButton: 'views-shared-specification-taskOverview-remarkTextsTile-viewAllButton',
          },
          photosCard: {
            photosList: 'views-shared-specification-taskOverview-photosCard-photosList',
            viewPhotos: 'views-shared-specification-taskOverview-photosCard-viewPhotos',
            title: 'views-shared-specification-taskOverview-photosCard-title',
            uploadPhotoButton: 'views-shared-specification-taskOverview-photosCard-uploadPhotoButton',
          },
          tasksTable: {
            searchBar: 'views-shared-specification-taskOverview-tasksTable-searchBar',
            bulkOperations: 'views-shared-specification-taskOverview-tasksTable-bulkOperations',
            tableContainer: 'views-shared-specification-taskOverview-tasksTable-tableContainer',
            tableHead: 'views-shared-specification-taskOverview-tasksTable-tableHead',
            tableHeadCheckBox: 'views-shared-specification-taskOverview-tasksTable-tableHeadCheckBox',
            tableRow: 'views-shared-specification-taskOverview-tasksTable-tableRow',
            tableRowCheckBox: 'views-shared-specification-taskOverview-tasksTable-tableRowCheckBox',
          },
          taskDocumentsTable: {
            container: 'views-shared-specification-taskOverview-taskDocumentsTable-container',
            createDocumentButton: 'views-shared-specification-taskOverview-taskDocumentsTable-createDocumentButton',
            tableCells: {
              createdAt: 'views-shared-specification-taskOverview-taskDocumentsTable-tableCells-createdAt',
              document: 'views-shared-specification-taskOverview-taskDocumentsTable-tableCells-document',
              createdBy: 'views-shared-specification-taskOverview-taskDocumentsTable-tableCells-createdBy',
            },
            tableRow: 'views-shared-specification-taskOverview-taskDocumentsTable-tableRow',
            tableRowDownloadButton: 'views-shared-specification-taskOverview-taskDocumentsTable-tableRowDownloadButton',
            tableRowDeleteButton: 'views-shared-specification-taskOverview-taskDocumentsTable-tableRowDeleteButton',
            tableRowDetailButton: 'views-shared-specification-taskOverview-taskDocumentsTable-tableRowDetailButton',
          },
        },
        tasksView: {
          header: {
            deleteButton: 'views-shared-specification-tasksView-header-deleteButton',
          },
          tasksTable: {
            tableContainer: 'views-shared-specification-tasksView-tasksTable-tableContainer',
            tableRow: 'views-shared-specification-tasksView-tasksTable-tableRow',
            minuteValueEditField: 'views-shared-specification-tasksView-tasksTable-minuteValueEditField',
            buttons: {
              remarkTexts: 'views-shared-specification-tasksView-tasksTable-buttons-remarkTexts',
              detail: 'views-shared-specification-tasksView-tasksTable-buttons-detail',
              edit: 'views-shared-specification-tasksView-tasksTable-buttons-edit',
              save: 'views-shared-specification-tasksView-tasksTable-buttons-save',
            },
            columns: {
              cardinalNumber: 'views-shared-specification-tasksView-tasksTable-columns-cardinalNumber',
              contingencyType: 'views-shared-specification-tasksView-tasksTable-columns-contingencyType',
              description: 'views-shared-specification-tasksView-tasksTable-columns-description',
              minuteValue: 'views-shared-specification-tasksView-tasksTable-columns-minuteValue',
              progress: 'views-shared-specification-tasksView-tasksTable-columns-progress',
            },
          },
        },
        overviewTiles: {
          priceProgressInfoBox: {
            detail: 'views-shared-specification-overviewTiles-priceProgressInfoBox-detail',
            percentageOnly: 'views-shared-specification-overviewTiles-priceProgressInfoBox-percentageOnly',
          },
          timeEstimateInfoBox: {
            detail: 'views-shared-specification-overviewTiles-timeEstimateInfoBox-detail',
            percentageOnly: 'views-shared-specification-overviewTiles-timeEstimateInfoBox-percentageOnly',
          },
          preambleCard: {
            text: 'views-shared-specification-overviewTiles-preambleCard-text',
            button: 'views-shared-specification-overviewTiles-preambleCard-button',
          },
          postscriptCard: {
            text: 'views-shared-specification-overviewTiles-postscriptCard-text',
            button: 'views-shared-specification-overviewTiles-postscriptCard-button',
          },
        },
        remarkTextsModal: {
          container: 'views-shared-specification-remarkTextsModal-container',
          closeButton: 'views-shared-specification-remarkTextsModal-closeButton',
          endMessage: 'views-shared-specification-remarkTextsModal-endMessage',
          card: {
            header: 'views-shared-specification-remarkTextsModal-card-header',
            body: 'views-shared-specification-remarkTextsModal-card-body',
          },
        },
        additionalTextsView: {
          emptyMessage: 'views-shared-specification-overviewTiles-additionalTextsView-emptyMessage',
          error: 'views-shared-specification-overviewTiles-additionalTextsView-error',
          card: {
            shortDescription: 'views-shared-specification-overviewTiles-additionalTextsView-card-shortDescription',
          },
        },
      },
      profile: {
        form: {
          container: 'views-shared-profile-form-container',
          firstName: 'views-shared-profile-form-firstName',
          lastName: 'views-shared-profile-form-lastName',
          phoneLandline: 'views-shared-profile-form-phoneLandline',
          phoneMobile: 'views-shared-profile-form-phoneMobile',
          email: 'views-shared-profile-form-email',
          emailRepeat: 'views-shared-profile-form-emailRepeat',
          password: 'views-shared-profile-form-password',
          passwordRepeat: 'views-shared-profile-form-passwordRepeat',
          submitForm: 'views-shared-profile-form-submitForm',
          resetButton: 'views-shared-profile-form-resetButton',
          successAlert: 'views-shared-profile-form-successAlert',
          imageUpload: {
            container: 'views-shared-profile-form-imageUpload-container',
            removeButton: 'views-shared-profile-form-imageUpload-resetButton',
            addButton: 'views-shared-profile-form-imageUpload-addButton',
            image: 'views-shared-profile-form-imageUpload-image',
            svg: 'views-shared-profile-form-imageUpload-svg',
            errorText: 'views-shared-profile-form-imageUpload-errorText',
          },
        },
      },
      legalEntity: {
        form: {
          name: 'views-shared-legalEntity-form-name',
          submitForm: 'views-shared-legalEntity-form-submitForm',
          successAlert: 'views-shared-legalEntity-form-successAlert',
          resetButton: 'views-shared-legalEntity-form-resetButton',
          container: 'views-shared-legalEntity-form-container',
          imageUpload: {
            container: 'views-shared-legalEntity-form-imageUpload',
            removeButton: 'views-shared-legalEntity-form-imageUpload-resetButton',
            addButton: 'views-shared-legalEntity-form-imageUpload-addButton',
            image: 'views-shared-legalEntity-form-imageUpload-image',
            svg: 'views-shared-legalEntity-form-imageUpload-svg',
            errorText: 'views-shared-legalEntity-form-imageUpload-errorText',
          },
        },
      },
    },
  },
  components: {
    analyticsOptOut: {
      container: 'components-analyticsOptOut-container',
      button: {
        optOut: 'components-analyticsOptOut-button-optOut',
        optIn: 'components-analyticsOptOut-button-optIn',
      },
    },
    contactProfileSidebar: {
      container: 'components-contactProfileSidebar-container',
      profileHeadline: 'components-contactProfileSidebar-profileHeadline',
      profileItem: 'components-contactProfileSidebar-profileItem',
      settingsItem: 'components-contactProfileSidebar-settingsItem',
      companyHeadline: 'components-contactProfileSidebar-companyHeadline',
      contactItem: 'components-contactProfileSidebar-contactItem',
      storageItem: 'components-contactProfileSidebar-storageItem',
    },
    dateRangePicker: {
      startDate: 'components-dateRangePicker-startDate',
      endDate: 'components-dateRangePicker-endDate',
    },
    dynamicAutocomplete: {
      listbox: 'components-dynamicAutocomplete-listbox',
      loadingIndicator: 'components-dynamicAutocomplete-loadingIndicator',
    },
    header: {
      container: 'components-header-container',
      breadcrumbs: 'components-header-breadcrumbs',
      headline: 'components-header-headline',
    },
    helpTooltip: {
      tooltip: 'components-helpTooltip-tooltip',
    },
    table: {
      resetButton: 'components-table-resetButton',
      submitButton: 'components-table-submitButton',
      expandButton: 'components-table-expandButton',
      collapseButton: 'components-table-collapseButton',
      bulkOperations: 'components-table-bulkOperations',
      bulkOperationsSelectionCount: 'components-table-bulkOperationsSelectionCount',
      checkbox: 'components-table-checkbox',
      bulkOperationsUnSelectAll: 'components-table-bulkOperationsUnSelectAll',
      searchBar: 'components-table-searchBar',
      clearSearchButton: 'components-table-clearSearchButton',
      filterButton: 'components-table-filterButton',
      startDate: 'components-table-startDate',
      endDate: 'components-table-endDate',
      uploadInput: 'components-table-uploadInput',
      common: {
        createdByColumn: 'components-table-common-createdByColumn',
      },
    },
    timeBookingsTurnoverChartFilterModal: {
      modalTitle: 'components-timeBookingsTurnoverChartFilterModal-modalTitle',
    },
    map: {
      marker: 'components-map-marker',
    },
    photoGallery: {
      arrowLeft: 'components-photoGallery-arrowLeft',
      arrowRight: 'components-photoGallery-arrowRight',
      closeButton: 'components-photoGallery-closeButton',
      mapContainer: 'components-photoGallery-mapContainer',
      gpsCoordinates: 'components-photoGallery-gpsCoordinates',
      editButton: 'components-photoGallery-editButton',
      deleteButton: 'components-photoGallery-deleteButton',
      image: 'components-photoGallery-image',
      modal: 'components-photoGallery-modal',
      textContainer: 'components-photoGallery-textContainer',
      generalInformation: 'components-photoGallery-generalInformation',
      createdAndModifiedInformation: 'components-photoGallery-createdAndModifiedInformation',
      projectLinks: 'components-photoGallery-projectLinks',
    },
    photoCard: {
      tile: 'components-photoCard-tile',
      tileInfo: 'components-photoCard-tileInfo',
      selectCheckBox: 'components-photoCard-selectCheckBox',
      tileInfoTitle: 'components-photoCard-tileInfoTitle',
      tileInfoDate: 'components-photoCard-tileInfoDate',
    },
    specification: {
      specificationDeleteDialog: {
        dialog: 'components-specification-specificationDeleteDialog',
        specificationName: 'components-specification-specificationDeleteDialog-specificationName',
        abortButton: 'components-specification-specificationDeleteDialog-abortButton',
        deleteButton: 'components-specification-specificationDeleteDialog-deleteButton',
        textField: 'components-specification-specificationDeleteDialog-textField',
      },
    },
    taskSpecificationsLink: {
      taskLinks: 'components-taskSpecificationsLink-taskLinks',
    },
    userInfo: {
      avatar: 'components-userInfo-avatar',
      text: 'components-userInfo-text',
    },
    exportForm: {
      exportButton: 'components-exportForm-exportButton',
      resetButton: 'components-exportForm-resetButton',
      chooseColumnsModalButton: 'components-exportForm-chooseColumnsModalButton',
      csvRadioButton: 'components-exportForm-csvRadioButton',
      excelRadioButton: 'components-exportForm-excelRadioButton',
      selectedColumnsLabel: 'components-exportForm-selectedColumnsLabel',
      modalTitle: 'components-exportForm-modalTitle',
      dragDropColumnsSelector: {
        droppable: 'components-exportForm-dragDropColumnsSelector-droppable',
      },
    },
  },
};
